import React, { useContext, useEffect } from 'react';
import { SetupAuth } from '../../shared/utils';

const AuthenticationContext = React.createContext();
export const useAuthenticationContext = () => useContext(AuthenticationContext);

const { Provider, Consumer } = AuthenticationContext;

const AuthenticationContextProvider = ({ children }) => {

    const emptyAuth = {
        authenticated: false,
        user: {
            name: '',
            email: '',
            token: '',
            role: ''
        }
    };

    const [auth, setAuth] = React.useState(emptyAuth);

    const logOff = () => {
        setAuth(emptyAuth);
    };

    const logIn = (user) => {
        setAuth({
            authenticated: true,
            user: {
                memberId: user.memberId,                
                name: user.name,
                userName: user.userName,
                token: user.token,
                role: user.role,
                g12LeadershipName: user.g12LeadershipName,
            }
        });
    };

    const getHeaders = () => {
        return {
            'Authorization': 'Bearer ' + auth.user.token
        };
    };

    const refreshAuthState = () => {        
        const storedAuth = localStorage.getItem('auth');
        if (storedAuth) {
            setAuth(JSON.parse(storedAuth));
        }
    };

    useEffect(() => {
        refreshAuthState();
    }, []);

    useEffect(() => {
        SetupAuth(auth);
    }, [auth]);

    return (
        <Provider value={{ auth, logOff, logIn, getHeaders, refreshAuthState }}>
            {children}
        </Provider>
    );
}

export { AuthenticationContextProvider, Consumer as ConsumerProvider };
export default AuthenticationContext;